import * as React from 'react'

function Copyright(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.172324 0C0.0768565 0 0 0.0768564 0 0.172324V21.8277C0 21.9231 0.0768565 22 0.172324 22H21.8277C21.9231 22 22 21.9231 22 21.8277V0.172324C22 0.0768564 21.9231 0 21.8277 0H0.172324ZM0.976501 0.976501H21.0235V21.0235H0.976501V0.976501Z"
        fill="#155EEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0098 16.3993C8.01317 16.3993 5.47021 13.9199 5.47021 10.8598C5.47021 8.03904 7.7109 5.34076 11.0309 5.34076C13.9665 5.34076 16.5309 7.70754 16.5309 10.873C16.5309 13.0866 15.2102 14.4335 15.2102 14.4335C15.6427 14.905 16.0936 15.3368 16.9164 15.3368C16.997 15.3368 17.06 15.3744 17.06 15.4804V16.5144C17.06 16.6131 16.9971 16.658 16.9164 16.658C15.5876 16.658 14.7878 15.9286 14.0637 15.0499C13.7031 14.6764 13.1992 14.2605 12.7938 14.1686C12.569 14.1166 12.5452 13.9943 12.6727 13.8752C12.9124 13.6255 13.228 13.4992 13.6255 13.4992L14.151 13.5918C14.6007 13.0768 15.1871 12.1533 15.1871 10.8766C15.1871 8.62875 13.3996 6.68922 11.0088 6.68922C8.7326 6.68922 6.81133 8.52521 6.81133 10.8786C6.81133 12.927 8.46984 15.0594 11.0918 15.0594C11.2626 15.0609 11.5657 15.0167 11.7334 15.0167C12.2658 15.0167 12.6308 15.2781 13.0003 15.7808C13.0824 15.8797 13.104 15.9975 12.8898 16.0634C12.4987 16.1995 11.7646 16.3993 11.0098 16.3993Z"
        fill="#155EEF"
      />
    </svg>
  )
}

export default React.memo(Copyright)
