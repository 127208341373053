import React from 'react'
import { CheckIcon } from '@mantine/core'
import { notifications } from '@mantine/notifications'

export const notifySuccess = (title: string, message: string, duration = 4000) => {
  notifications.show({
    title,
    message,
    color: 'green',
    autoClose: duration,
    icon: <CheckIcon className="size-4" />,
  })
}
