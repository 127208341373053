import { produce } from 'immer'
import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { createSelectors } from '@/util/create-selectors'

export type Account = { value: string; label: string; img?: string; followers?: number }
type Filter = { value: string; label: string }
type Category = { value: string; label: string }

interface Filters {
  accounts: Account[]
  languages: Filter[]
  formats: Filter[]
  tags: Filter[]
  timeframe: string
  sortBy: string
  query: string
  categories: Category[]
}

interface State {
  platform: 'instagram' | 'tiktok'
  instagram: Filters
  tiktok: Filters
  vars: {
    caption_query: string
    profiles: {
      query: string
      selected: { id: string }[]
    }
    languages: { id: string; name: string }[]
    post_formats: { id: string; name: string }[]
    tags: { id: string; name: string }[]
    profile_categories: { id: string }[]
  }
}

export const defaultFilters = {
  query: '',
  accounts: [],
  languages: [],
  formats: [],
  tags: [],
  categories: [],
  sortBy: 'taken_at',
  timeframe: 'LAST_30_DAYS',
}

const defaultState = {
  platform: 'instagram' as const,
  instagram: defaultFilters,
  tiktok: defaultFilters,
  vars: {
    caption_query: '',
    profiles: {
      query: '',
      selected: [],
    },
    languages: [],
    post_formats: [],
    tags: [],
    profile_categories: [],
  },
}

interface Actions {
  //   setFilters: (updateFn: (draft: State) => void) => void
  setFilters: (updateFn: (draft: Filters) => Partial<Filters>) => void
  setPlatform: (platform: 'instagram' | 'tiktok') => void
  resetFilters: () => void
  setVars: () => void

  //   setTimeframe: (timeframe: string) => void
  //   setSortBy: (sortBy: string) => void
}

type Store = State & Actions

export const useIGStoreBase = create<Store>()(
  persist(
    immer((set, get) => ({
      ...defaultState,
      //   setFilters: (updateFn) =>
      //     set(
      //       produce((state) => {
      //         state[state.platform] = produce(updateFn)(state[state.platform])
      //       })
      //     ),

      setPlatform: (platform) => set({ platform }),

      //   setSortBy: (sortBy) => set({ sortBy }),
      //   setFilters: (updateFn) =>
      //     set((state) => ({
      //       [state.platform]: produce(updateFn),
      //     })),
      //   setTimeframe: (timeframe) => set({ timeframe }),
      setFilters: (payload) =>
        set(
          produce((draft) => {
            const platform = draft.platform
            draft[platform] = { ...draft[platform], ...payload(draft[platform]) }
          })
        ),

      resetFilters: () => {
        const { platform, ...rest } = defaultState
        return set(rest)
      },

      setVars: () =>
        set((state) => {
          const current = state[state.platform]
          state.vars = {
            caption_query: current.query ?? '',
            profiles: {
              query: '',
              selected: current.accounts.map((a) => ({ id: a.value })),
            },
            languages: current.languages.map((l) => ({ id: l.value, name: l.label })),
            post_formats: current.formats.map((f) => ({ id: f.value, name: f.label })),
            tags: current.tags.map((t) => ({ id: t.value, name: t.label })),
            profile_categories: current.categories.map((c) => ({ id: c.value })),
          }
        }),
    })),
    {
      name: 'ig',
      storage: createJSONStorage(() => localStorage),
      version: 0.3,
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !['vars'].includes(key))),
    }
  )
)

export const useIGStore = createSelectors(useIGStoreBase)

export const useTrackedIGStore = createTrackedSelector(useIGStoreBase)

export const TIME_RANGE_OPTIONS = [
  {
    id: 'ALL_TIME',
    name: 'All time',
  },
  {
    id: 'LAST_7_DAYS',
    name: 'Last 7 days',
  },
  {
    id: 'LAST_14_DAYS',
    name: 'Last 14 days',
  },
  {
    id: 'LAST_30_DAYS',
    name: 'Last 30 days',
  },
] as const
