import * as React from 'react'

function File(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.48277 1.16669H4.27996C3.97332 1.16669 3.67924 1.2896 3.46242 1.5084C3.24559 1.72719 3.12378 2.02393 3.12378 2.33335V11.6667C3.12378 11.9761 3.24559 12.2729 3.46242 12.4916C3.67924 12.7104 3.97332 12.8334 4.27996 12.8334H11.217C11.5237 12.8334 11.8178 12.7104 12.0346 12.4916C12.2514 12.2729 12.3732 11.9761 12.3732 11.6667V4.08335L9.48277 1.16669Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.90454 1.16669V3.50002C8.90454 3.80944 9.02635 4.10619 9.24318 4.32498C9.46 4.54377 9.75408 4.66669 10.0607 4.66669H12.3731"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.43604 10.5V9.91669" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.74854 10.5V7" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0608 10.5V8.75" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default React.memo(File)
