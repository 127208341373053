import React, { FunctionComponent, useState } from 'react'
import Intercom from '@intercom/messenger-js-sdk'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import { VeltProvider } from '@veltdev/react'
import { PostHogProvider } from 'posthog-js/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import superjson from 'superjson'

import { ToastProvider } from '@/contexts/toastContext'
import { trpc } from '@/util/trpc'
import { ErrorFallback } from './components/ErrorFallback'
import { I18n } from './components/I18n'
import { INTERCOM_APP_ID } from './constants'
import { Routes } from './routes'
import { theme } from './theme'

const App: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  Intercom({
    app_id: INTERCOM_APP_ID,
  })
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            useErrorBoundary: true,
          },
        },
      })
  )
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpLink({
          url: import.meta.env.VITE_API_URL + 'trpc',
          fetch(url, options) {
            return fetch(url, {
              ...options,
              headers: {
                ...options?.headers,
                'x-remember-me': localStorage.getItem('rememberMe') || 'false',
              },
              credentials: 'include',
            })
          },
        }),
      ],
    })
  )
  return (
    <>
      <PostHogProvider
        apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
        options={{
          autocapture: false,
          api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
          session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
              password: true, // Highly recommended as a minimum!!
              // color: false,
              // date: false,
              // 'datetime-local': false,
              // email: false,
              // month: false,
              // number: false,
              // range: false,
              // search: false,
              // tel: false,
              // text: false,
              // time: false,
              // url: false,
              // week: false,
              // textarea: false,
              // select: false,
            },
          },
        }}
      >
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <I18n>
              <VeltProvider apiKey={import.meta.env.VITE_VELT_API_KEY}>
                <MantineProvider theme={theme}>
                  <Notifications position="top-right" zIndex={505} />
                  <ToastProvider>
                    <DndProvider backend={HTML5Backend}>
                      <Routes />
                    </DndProvider>
                  </ToastProvider>
                </MantineProvider>
              </VeltProvider>
            </I18n>
          </QueryClientProvider>
        </trpc.Provider>
      </PostHogProvider>
    </>
  )
}

export default Sentry.withErrorBoundary(App, {
  fallback: ({ error, componentStack }) => {
    return <ErrorFallback title="Error" />
  },
})
