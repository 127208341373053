export enum UserRole {
  SUPERADMIN = 'Super admin',
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee',
}

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  DOMAIN_COMPLETED = 'DOMAIN_COMPLETED',
  INDUSTRY_COMPLETED = 'INDUSTRY_COMPLETED',
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
}
