import * as React from 'react'

function Diamond(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0845 7.52864C10.9607 7.65366 10.8911 7.82319 10.8911 7.99997C10.8911 8.17675 10.9607 8.34629 11.0845 8.4713L12.6543 10.056C12.7782 10.181 12.9462 10.2512 13.1214 10.2512C13.2966 10.2512 13.4646 10.181 13.5885 10.056L15.1589 8.4713C15.2828 8.34629 15.3524 8.17675 15.3524 7.99997C15.3524 7.82319 15.2828 7.65366 15.1589 7.52864L13.5885 5.94397C13.4646 5.81899 13.2966 5.74878 13.1214 5.74878C12.9462 5.74878 12.7782 5.81899 12.6543 5.94397L11.0845 7.52864Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33797 7.52864C2.21411 7.65366 2.14453 7.82319 2.14453 7.99997C2.14453 8.17675 2.21411 8.34628 2.33797 8.4713L3.90839 10.056C4.03228 10.181 4.2003 10.2512 4.37549 10.2512C4.55067 10.2512 4.71869 10.181 4.84258 10.056L6.41301 8.4713C6.53686 8.34628 6.60644 8.17675 6.60644 7.99997C6.60644 7.82319 6.53686 7.65366 6.41301 7.52864L4.84258 5.94397C4.71869 5.81899 4.55067 5.74878 4.37549 5.74878C4.2003 5.74878 4.03228 5.81899 3.90839 5.94397L2.33797 7.52864Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.71086 11.9413C6.64943 12.0032 6.6007 12.0767 6.56745 12.1577C6.5342 12.2386 6.51709 12.3254 6.51709 12.413C6.51709 12.5006 6.5342 12.5873 6.56745 12.6683C6.6007 12.7492 6.64943 12.8227 6.71086 12.8846L8.28128 14.4686C8.40517 14.5936 8.57319 14.6638 8.74838 14.6638C8.92356 14.6638 9.09158 14.5936 9.21547 14.4686L10.7859 12.8846C10.8473 12.8227 10.8961 12.7492 10.9293 12.6683C10.9625 12.5873 10.9797 12.5006 10.9797 12.413C10.9797 12.3254 10.9625 12.2386 10.9293 12.1577C10.8961 12.0767 10.8473 12.0032 10.7859 11.9413L9.21547 10.3573C9.09158 10.2323 8.92356 10.1621 8.74838 10.1621C8.57319 10.1621 8.40517 10.2323 8.28128 10.3573L6.71086 11.9413Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.71101 3.11604C6.58716 3.24106 6.51758 3.4106 6.51758 3.58737C6.51758 3.76415 6.58716 3.93369 6.71101 4.05871L8.28144 5.64271C8.40533 5.76769 8.57335 5.8379 8.74853 5.8379C8.92372 5.8379 9.09174 5.76769 9.21563 5.64271L10.7861 4.05871C10.9099 3.93369 10.9795 3.76415 10.9795 3.58737C10.9795 3.4106 10.9099 3.24106 10.7861 3.11604L9.21563 1.53137C9.09174 1.40639 8.92372 1.33618 8.74853 1.33618C8.57335 1.33618 8.40533 1.40639 8.28144 1.53137L6.71101 3.11604Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default React.memo(Diamond)
