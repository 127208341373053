import * as React from 'react'

function IncreasingGraph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4004_6995)">
        <path
          d="M6.4917 12.125V15.25"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.96924 10.875V15.25"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4468 8.375V15.25"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6855 4L7.33032 9.40375C7.30155 9.43285 7.26737 9.45594 7.22975 9.4717C7.19212 9.48745 7.15179 9.49556 7.11105 9.49556C7.07032 9.49556 7.02998 9.48745 6.99236 9.4717C6.95474 9.45594 6.92056 9.43285 6.89179 9.40375L4.85279 7.34625C4.79471 7.28767 4.71595 7.25475 4.63384 7.25475C4.55172 7.25475 4.47296 7.28767 4.41488 7.34625L0.297852 11.5"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.53662 13.375V15.25"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01416 10.875V15.25"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4004_6995">
          <rect width="14.8652" height="15" fill="white" transform="translate(0.297852)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default React.memo(IncreasingGraph)
