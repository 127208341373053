export const IS_DEV =
  import.meta.env.VITE_ENVIRONMENT === 'DEV' || import.meta.env.VITE_ENVIRONMENT === 'LOCAL'
export const IS_PROD = import.meta.env.VITE_ENVIRONMENT === 'PROD'
export const RESEARCH_LINK = IS_DEV ? 'https://search-dev.eyva.ai/' : 'https://search.eyva.ai/'

export const INTERCOM_APP_ID = 'v8thf2gu'

export const ETAILERS = {
  de: [
    'dm_de',
    'douglas_de',
    'flaconi_de',
    'net_a_porter_de',
    'niche_beauty_de',
    'notino_de',
    'rossmann_de',
    'sephora_de',
    'mueller_de',
  ],
  us: ['niche_beauty_com', 'sephora_com', 'ulta_com', 'net_a_porter_com'],
  uk: ['notino_uk', 'net_a_porter_uk', 'niche_beauty_uk', 'sephora_uk'],
}

export const REVIEWS_ETAILERS = {
  de: ['dm_de', 'douglas_de', 'flaconi_de', 'rossmann_de', 'sephora_de', 'mueller_de'],
  us: ['sephora_com', 'ulta_com'],
  uk: ['sephora_uk'],
}

export const CATEGORIES = [
  { c2_id: '-3041563466726729621', label: 'Skincare', img: '/categories/webp/skin care.webp' },
  { c2_id: '-8927773384010863663', label: 'Body', img: '/categories/webp/body.webp' },
  { c2_id: '-5260893170826292991', label: 'make up', img: '/categories/webp/make up.webp' },
  { c2_id: '3196609641275568313', label: 'hair', img: '/categories/webp/hair care.webp' },
  { c2_id: '-6608966659828261852', label: 'fragrance', img: '/categories/webp/fragrance.webp' },
  { c2_id: '-401414836000200262', label: 'Health', img: '/categories/webp/health.webp' },
  { c2_id: '3172394317186079035', label: 'Grooming', img: '/categories/webp/grooming.webp' },
  { c2_id: '7514492746299008347', label: 'Kids & Baby', img: '/categories/webp/kids.webp' },
  { c2_id: '1697101705461460363', label: 'sets', img: '/categories/webp/sets.webp' },
]

export const CI_CATEGORIES = [
  { c2_id: '3914228190679554989', label: 'skin care', img: '/categories/webp/skin care.webp' },
  { c2_id: '-5616082504844587515', label: 'bath & body', img: '/categories/webp/body.webp' },
  { c2_id: '-1856098516420443798', label: 'make up', img: '/categories/webp/make up.webp' },
  { c2_id: '-4299898496507070167', label: 'hair care', img: '/categories/webp/hair care.webp' },
  { c2_id: '3880378166348282584', label: 'fragrance', img: '/categories/webp/fragrance.webp' },
  { c2_id: '-1109885545695339203', label: 'nail care', img: '/categories/webp/nail care.webp' },
]

export const EXPORT_ROWS = 1000

export const TOPIC_TYPES = {
  ingredient: {
    title: 'Ingredient',
    description:
      'Search growth in early stages over the past 12 months, reflecting increasing interest and low competition, providing new opportunities.',
    color: '#F3FEE7',
    text: '#4CA30D',
  },
  concern: {
    title: 'Concern',
    description:
      'Search growth in early stages over the past 12 months, reflecting increasing interest and low competition, providing new opportunities.',
    color: '#FDF2FA',
    text: '#C11574',
  },
  feature: {
    title: 'Feature',
    description:
      'Search growth in early stages over the past 12 months, reflecting increasing interest and low competition, providing new opportunities.',
    color: '#F2F4F7',
    text: '#475467',
  },
  effect: {
    title: 'Effect',
    description:
      'Search growth in early stages over the past 12 months, reflecting increasing interest and low competition, providing new opportunities.',
    color: '#FDF2FA',
    text: '#E62E05',
  },
  brand: {
    title: 'Brand',
    description:
      'Consistent search growth observed over the past 12 months, with higher engagement and moderately competitive keywords.',
    color: '#F0FDF9',
    text: '#15B79E',
  },
  product_type: {
    title: 'Product type',
    description:
      'Consistent search growth observed over the past 12 months, with higher engagement and moderately competitive keywords.',
    color: '#EFF8FF',
    text: '#175CD3',
  },
  topic: {
    title: 'Topic',
    description:
      'Search growth in early stages over the past 12 months, reflecting increasing interest and low competition, providing new opportunities.',
    color: '#F3FEE7',
    text: '#4CA30D',
  },
}

export const magazineImages: { [key: string]: string } = {
  BODY_CARE: '/magazine/body.webp',
  CLEAN_BEAUTY: '/magazine/cleanbeauty.webp',
  CONCERNS: '/magazine/concerns.webp',
  EXPERTS: '/magazine/expert.webp',
  FRAGRANCE: '/magazine/fragrance.webp',
  HAIR_CARE: '/magazine/hair.webp',
  INGREDIENTS: '/magazine/ingredients.webp',
  MAKEUP: '/magazine/makeup.webp',
  PACKAGING: '/magazine/packaging.webp',
  SKINCARE: '/magazine/skincare.webp',
  SOCIAL_MEDIA: '/magazine/socialmedia.webp',
  TRENDS: '/magazine/trends.webp',
  BABY_CARE: '/magazine/babycare.webp',
  BRANDS: '/magazine/brands.webp',
  INFLUENCERS: '/magazine/influencers.webp',
}

export const ciTrendsImages = {
  SKINCARE: '/ci-trends/skincare.webp',
  BATH_BODY: '/ci-trends/bath.webp',
  MAKEUP: '/ci-trends/makeup.webp',
  HAIR: '/ci-trends/hair.webp',
  FRAGRANCE: '/ci-trends/fragrance.webp',
  CONCERN: '/ci-trends/concern.webp',
  BRAND: '/ci-trends/brand.webp',
  CI_TRENDS_BG: '/ci-trends/ci-trends-bg.webp',
  INSTAGRAM_LOGO: '/ci-trends/instagram-logo.webp',
  TIKTOK_LOGO: '/ci-trends/tiktok-logo.webp',
  NAIL: '/ci-trends/nail.webp',
  INGREDIENT: '/ci-trends/ingredient.webp',
}

export const socialMediaImages = {
  INSTAGRAM_SKINCARE_BRANDS: '/social-media/insta-one.webp',
  INSTAGRAM_MAKEUP_BRANDS: '/social-media/insta-two.webp',
  INSTAGRAM_HAIRCARE_BRANDS: '/social-media/insta-three.webp',
  TIKTOK_SKINCARE_BRANDS: '/social-media/tiktok-one.webp',
  TIKTOK_MAKEUP_BRANDS: '/social-media/tiktok-two.webp',
  TIKTOK_HAIRCARE_BRANDS: '/social-media/tiktok-three.webp',
  EMPTY: '/social-media/empty.webp',
}
