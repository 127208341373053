import * as React from 'react'

function Chart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 14.1179V19.1633"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12.0998V19.1633"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8.06349V19.1633"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 1L12.354 9.72442C12.3076 9.77141 12.2524 9.80868 12.1916 9.83412C12.1309 9.85955 12.0658 9.87265 12 9.87265C11.9342 9.87265 11.8691 9.85955 11.8084 9.83412C11.7476 9.80868 11.6924 9.77141 11.646 9.72442L8.354 6.40256C8.26024 6.30798 8.13308 6.25484 8.0005 6.25484C7.86792 6.25484 7.74076 6.30798 7.647 6.40256L1 13.1088"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 16.136V19.1633"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.0998V19.1633"
        stroke="#155EEF"
        strokeWidth="1.61451"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default React.memo(Chart)
