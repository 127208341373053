import * as React from 'react'

function Graph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.84985 10.625V5.625"
        stroke="#101828"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9468 10.625V3.125"
        stroke="#101828"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.65601 1.875V11.875C2.65601 12.2065 2.78652 12.5245 3.01883 12.7589C3.25114 12.9933 3.56623 13.125 3.89477 13.125H13.8049"
        stroke="#101828"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75293 10.625V8.75"
        stroke="#101828"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default React.memo(Graph)
