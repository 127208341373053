import { useCallback, useEffect, useState } from 'react'
import { ArrowNarrowLeftIcon, PlusSmIcon } from '@heroicons/react/outline'
import { ExclamationIcon, TrashIcon, XIcon } from '@heroicons/react/solid'
import { Button, Modal, Popover, Tooltip } from '@mantine/core'
import { useLocation, useNavigate } from 'react-router-dom'

import { DataLoader, Loader } from '@/components/Loader'
import { ShouldShowTooltip } from '@/components/Tooltip'
import { VideoModal } from '@/components/VideoModal'
import CompareIcon from '@/icons/Compare'
import ListIcon from '@/icons/List'
import { Link } from '@/router'
import { notifyError } from '@/util/error'
import { trpc } from '@/util/trpc'
import { useProductStore } from '../product-analysis/_hook'

export const ComparePopover = () => {
  const [opened, setOpened] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [isNewCompare, setIsNewCompare] = useState(false)
  const { products } = useProductStore()
  const [view, setView] = useState<'default' | 'add' | 'view'>('default')

  useEffect(() => {
    if (state?.comparePopover) {
      setView('view')
      setOpened(state?.comparePopover)
    }
  }, [])

  const onClose = useCallback(() => {
    setOpened((o) => !o)
    setView('default')
    if (state?.comparePopover) navigate('.', { state: { ...state, comparePopover: false } })
  }, [navigate, state])

  return (
    <Popover
      width={455}
      position="bottom"
      withArrow
      shadow="md"
      styles={{
        dropdown: {
          minHeight: 400,
          maxHeight: 500,
          overflowY: 'auto',
        },
      }}
      opened={opened}
      onChange={onClose}
    >
      <Popover.Target>
        <Tooltip
          label={
            <div className="space-y-1">
              <p className="font-semibold">Product Comparisons</p>
              <p>Click here to manage your product comparisons.</p>
            </div>
          }
          position="bottom"
          w={230}
        >
          <button className="rounded-md bg-white p-2" onClick={() => setOpened((o) => !o)}>
            <CompareIcon className="size-5" />
          </button>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex flex-col space-y-4 text-sm">
          <div className="flex items-center justify-between">
            <h2 className="font-medium">Comparisons</h2>
            <VideoModal
              href="https://eyva-images.s3.eu-central-1.amazonaws.com/eyva+-+New+Comparison.mp4"
              className="text-xs font-medium text-accent-600 underline underline-offset-2"
              modalProps={{
                withinPortal: false,
                styles: {
                  inner: {
                    display: 'block',
                    maxWidth: '950px',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  },
                },
              }}
            >
              Learn how to compare
            </VideoModal>
          </div>
          {view === 'default' ? (
            <div className="space-y-3">
              <Link
                to={{
                  pathname: '/product-comparison/:id',
                  search: location.search,
                }}
                params={{ id: 'undefined' }}
              >
                <ShouldShowTooltip
                  show={products.length === 0}
                  label="Please select products first."
                  position="right"
                >
                  <button
                    disabled={products.length === 0}
                    className="flex w-full items-center space-x-2 rounded-md border border-primary-200 p-3 hover:bg-gray-50 disabled:bg-transparent disabled:text-primary-500"
                    onClick={() => {
                      setIsNewCompare((prev) => !prev)
                    }}
                  >
                    <CompareIcon className="size-4" /> <p>Show current comparison</p>
                  </button>
                </ShouldShowTooltip>
              </Link>
              <ShouldShowTooltip
                show={products.length === 0}
                label="Please select products first."
                position="right"
              >
                <button
                  disabled={products.length === 0}
                  className="flex w-full items-center space-x-2 rounded-md border border-primary-200 p-3 hover:bg-gray-50 disabled:bg-transparent disabled:text-primary-500"
                  onClick={() => {
                    setView('add')
                  }}
                >
                  <PlusSmIcon className="size-4" /> <p>Add to existing comparison</p>
                </button>
              </ShouldShowTooltip>
              <button
                disabled={isNewCompare}
                className="flex w-full items-center space-x-2 rounded-md border border-primary-200 p-3 hover:bg-gray-50 disabled:pointer-events-none disabled:text-primary-500"
                onClick={() => {
                  setView('view')
                }}
              >
                <ListIcon className="size-4" />
                <p>View existing comparison</p>
              </button>
            </div>
          ) : view === 'add' ? (
            <div className="space-y-3">
              <p className="text-xs text-primary-500">
                Please choose comparison below, click Add and selected products will be added to the
                choosen comparison.
              </p>
              <ComparisonList disable="remove" />
              <button
                className="flex items-center text-accent-600"
                onClick={() => setView('default')}
              >
                <ArrowNarrowLeftIcon className="mr-2 size-4 " />
                <span className="text-xs font-normal">Back</span>
              </button>
            </div>
          ) : (
            <div className="space-y-3">
              <p className="text-xs text-primary-500">
                Please choose comparison below, click Add and selected products will be added to the
                choosen comparison.
              </p>
              <ComparisonList disable="add" />
              <button
                className="flex items-center text-accent-600"
                onClick={() => setView('default')}
              >
                <ArrowNarrowLeftIcon className="mr-2 size-4 " />
                <span className="text-xs font-normal">Back</span>
              </button>
            </div>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}

export const ComparisonList = ({ disable }: { disable: 'add' | 'remove' }) => {
  const { data: productComparisons, isLoading } = trpc.comparison.getComparisons.useQuery()

  const filteredProductComparisons = productComparisons?.filter((comparison) => comparison.name)

  return (
    <div className="max-h-[390px] min-h-[300px] space-y-3 overflow-y-auto">
      {isLoading ? (
        <DataLoader height={200} />
      ) : filteredProductComparisons?.length === 0 ? (
        <div className="mx-auto flex h-full max-w-[75%] grow flex-col items-center justify-center space-y-2 text-center text-xs">
          <p className="font-semibold">No saved comparisons</p>
          <p>
            It seems like you have no saved comparisons at the moment. Please take a moment to
            explore our short tutorial, and start comparing.
          </p>
        </div>
      ) : (
        filteredProductComparisons?.map((comparison) => {
          const noOfProducts = comparison.p_c_ids.length
          return (
            <div
              key={comparison.id}
              className="flex items-center justify-between rounded-md border border-primary-200 px-3 py-2"
            >
              <div className="space-y-0.5">
                <p>{comparison.name}</p>
                <p className="text-xs text-primary-500">{noOfProducts} products</p>
              </div>
              <div className="flex items-center space-x-2">
                {disable !== 'add' && <AddButton id={comparison.id} />}
                <Link
                  to={{
                    pathname: '/product-comparison/:id',
                    search: location.search,
                  }}
                  params={{ id: comparison.id }}
                >
                  <ShouldShowTooltip
                    show={noOfProducts === 0}
                    label="Please add products to this comparison."
                    position="bottom"
                  >
                    <button
                      disabled={noOfProducts === 0}
                      className="rounded bg-gray-100 px-3 py-0.5 text-xxs text-primary-900 disabled:text-primary-500"
                    >
                      View
                    </button>
                  </ShouldShowTooltip>
                </Link>
                {disable !== 'remove' && <ConfirmationModal id={comparison.id} />}
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

export const AddButton = ({ id }: { id: string }) => {
  const utils = trpc.useContext()
  const { products, resetProducts } = useProductStore()
  const { mutate, isLoading } = trpc.comparison.updateComparison.useMutation({
    onSuccess: () => {
      utils.comparison.getComparisons.invalidate()
      resetProducts()
    },
  })
  return (
    <ShouldShowTooltip
      show={products.length === 0}
      label="Please select products first."
      position="bottom"
    >
      <button
        disabled={products.length === 0}
        className="inline-flex items-center space-x-1 rounded bg-gray-100 px-3 py-0.5 text-xxs text-primary-900 disabled:text-primary-500"
        onClick={() => {
          mutate({ id, p_c_ids: products.map((p) => p.id) })
        }}
      >
        {isLoading && <Loader className="size-3 border-2 border-gray-800" />} <p>Add</p>
      </button>
    </ShouldShowTooltip>
  )
}

export const ConfirmationModal = ({ id }: { id: string }) => {
  const [opened, setOpened] = useState(false)

  const utils = trpc.useContext()
  const { mutate, isLoading: isDeleting } = trpc.comparison.deleteComparison.useMutation({
    async onMutate(variables) {
      await utils.comparison.getComparisons.cancel()
      const previousData = utils.comparison.getComparisons.getData()
      utils.comparison.getComparisons.setData(undefined, (old) => {
        if (old) {
          return old.filter((c) => c.id !== variables.id)
        }
      })
      return { previousData }
    },
    onError: (err, newData, context) => {
      notifyError(err)
      utils.comparison.getComparisons.setData(undefined, context?.previousData)
    },
    onSettled: () => {
      utils.comparison.getComparisons.invalidate()
    },
  })
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        centered
        size={444}
        withinPortal={false}
        withCloseButton={false}
        className="relative"
        p={0}
        zIndex={1000}
        styles={{
          inner: {
            display: 'block',
            maxWidth: '600px',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <button
          className="absolute right-8 top-8"
          onClick={(e) => {
            setOpened(false)
          }}
        >
          <XIcon className="size-5 text-primary-500" />
        </button>
        <div className="mx-auto flex min-h-[350px] max-w-[80%] flex-col justify-center space-y-4 text-center">
          <div className="mx-auto flex items-center justify-center space-x-1 text-center">
            <ExclamationIcon className=" size-12 rounded-full border-[6px] border-red-50 bg-red-100 p-1 text-red-700" />
          </div>
          <h1 className="text-base font-semibold">
            Are you sure you <br /> want to delete your saved comparison?
          </h1>
          <p className="text-sm text-primary-500">This action can&apos;t be undone!</p>
          <div className="grid grid-cols-5 gap-2">
            <Button
              variant="default"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setOpened(false)
              }}
              fullWidth
              className="col-span-2"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                mutate({ id })
                setOpened(false)
              }}
              fullWidth
              className="col-span-3"
            >
              Delete Comparison
            </Button>
          </div>
        </div>
      </Modal>

      <button
        onClick={(e) => {
          setOpened(true)
        }}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <Loader className="size-4 border-2 " />
        ) : (
          <TrashIcon className="size-4 text-primary-500" />
        )}
      </button>
    </>
  )
}
