import React, { useMemo, useState } from 'react'
import { Button, Checkbox, Rating } from '@mantine/core'
import { xorBy } from 'lodash'
import { usePostHog } from 'posthog-js/react'
import { Link } from 'react-router-dom'

import { HoverLongText } from '@/components/HoverLongText'
import { formatCurrency } from '@/util/formatCurrency'
import { formatNumber } from '@/util/formatNumber'
import { RouterOutput, trpc } from '@/util/trpc'
import { useMIVars } from '../../market-insights/_hook'
import { useProductStore } from '../_hook'

export const ProductCard = ({
  product,
  disableCompare,
  isNew,
}: {
  product: RouterOutput['product_insights']['allProductData']['items'][number]
  disableCompare?: boolean
  isNew?: boolean
}) => {
  const utils = trpc.useContext()
  const posthog = usePostHog()
  const { vars } = useMIVars()
  const [hover, setHover] = useState(false)
  const p_c_id = useMemo(() => product.p_c_id, [product])

  const { products, isProductSelected, toggleProduct } = useProductStore()

  return (
    <div key={p_c_id} className={`w-full rounded-md border border-primary-200`}>
      <div
        className="relative w-full p-4 text-center "
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isNew && ( // Conditionally render the new indicator
          <div className="absolute left-2 top-2 z-10 rounded-full px-2 text-sm  font-medium text-accent-600">
            *New
          </div>
        )}
        {hover && ( // Buttons on hover
          <div className="absolute inset-0 z-20 flex h-full flex-col justify-center bg-primary-200/50">
            <div className="mx-auto w-[77%] space-y-2">
              <Link
                to={`/product-details/${p_c_id}${location.search}`}
                className="font-medium hover:text-purple-700"
                onClick={() => {
                  posthog?.capture(`Product Clicked`, {
                    p_c_id,
                  })
                }}
              >
                <Button variant="default" fullWidth>
                  Product Details
                </Button>
              </Link>
            </div>
          </div>
        )}
        <img
          src={product.image_url ?? '/missing-img.jpeg'}
          alt="product img"
          className="mx-auto size-24"
        />
        <Checkbox
          className="absolute right-4 top-4 z-[21]"
          checked={isProductSelected(p_c_id)}
          onChange={() => {
            toggleProduct(p_c_id)
            utils.product_insights.productsDetails.cancel()
            utils.product_insights.productsDetails.setData(
              {
                geo: vars.geo,
                c2_id: vars.c2_id,
                p_c_ids: products.map((p) => p.id),
              },
              (old) => {
                if (old) {
                  const pcp = xorBy(
                    old,
                    [{ ...product, brand: product.brand_norm }],
                    (o) => o.p_c_id
                  )
                  return pcp
                }
              }
            )
            utils.product_insights.productsDetails.invalidate()
          }}
        />
        <Rating value={product.rating_avg} readOnly className="mx-auto my-2" />
        <p className="text-xs text-primary-500">{formatNumber(product.reviews_count)} Reviews</p>
      </div>
      <div className="space-y-1 p-4">
        <p className="truncate">{product.brand_norm}</p>
        <div className="truncate text-primary-500">
          <HoverLongText>{product.title}</HoverLongText>
        </div>
        <p className="text-xs">
          {product?.min_price == product?.max_price ? (
            <span>{formatCurrency(product?.min_price, vars.geo)}</span>
          ) : (
            <span>
              {formatCurrency(product?.min_price, vars.geo)} -{' '}
              {formatCurrency(product?.max_price, vars.geo)}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}
