import { useCallback } from 'react'
import { uniqBy, xorBy } from 'lodash'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { notifySuccess } from '@/util/success'
import { trpc } from '@/util/trpc'
import { useMIVars } from '../market-insights/_hook'

export type Product = { id: number }

interface State {
  products: Product[]
}

interface Actions {
  setProducts: (f: Product[]) => void
  toggleProducts: (f: Product[]) => void
  addProducts: (f: Product[]) => void
  resetProducts: () => void
}

export const usePIStoreBase = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      products: [],

      setProducts(f) {
        set((state) => {
          state.products = f
        })
      },

      toggleProducts(f) {
        set((state) => {
          state.products = xorBy(state.products, f, (o) => o.id)
        })
      },

      addProducts(f) {
        set((state) => {
          state.products = uniqBy([...state.products, ...f], (o) => o.id)
        })
      },

      resetProducts: () => set({ products: [] }),
    })),
    {
      name: 'pi',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !['vars'].includes(key))),
    }
  )
)

// export const usePIStore = createSelectors(usePIStoreBase)

// export const useTrackedPIStore = createTrackedSelector(usePIStoreBase)

export const useProductStore = () => {
  const utils = trpc.useContext()
  const { vars } = useMIVars()
  const { data: products, isLoading } = trpc.comparison.getTempComparison.useQuery(undefined)
  const { mutate: enqueueClaimsImages } = trpc.product_insights.enqueueClaimsImages.useMutation()
  const { mutate: updateTempComparison } = trpc.comparison.updateTempComparison.useMutation()

  const isProductSelected = useCallback(
    (p_c_id) => (products ?? []).findIndex((p) => p.id === p_c_id) > -1,
    [products]
  )

  const toggleProduct = useCallback(
    (id: number) => {
      utils.comparison.getTempComparison.setData(undefined, (old) => {
        if (!old) return
        const isProductSelected = old?.findIndex((p) => p.id === id) > -1
        if (!isProductSelected) {
          if (old.length > 0)
            notifySuccess('Success!', 'The product has been added to the comparison list.', 2000)
          enqueueClaimsImages({ p_c_ids: [id], geo: vars.geo })
        }
        const newProducts = xorBy(old, [{ id }], (o) => o.id)
        updateTempComparison({ p_c_ids: newProducts?.map((p) => p.id) })
        return newProducts
      })
    },
    [vars.geo]
  )

  const resetProducts = useCallback(() => {
    utils.comparison.getTempComparison.setData(undefined, () => [])
    updateTempComparison({ p_c_ids: [] })
  }, [])

  const addProducts = useCallback(
    (ids: { id: number }[]) => {
      utils.comparison.getTempComparison.setData(undefined, (old) => {
        if (!old) return

        enqueueClaimsImages({ p_c_ids: ids.map((i) => i.id), geo: vars.geo })
        const newProducts = uniqBy([...old, ...ids], (o) => o.id)
        updateTempComparison({ p_c_ids: newProducts?.map((p) => p.id) })
        return newProducts
      })
    },
    [vars.geo]
  )

  return {
    products: products ?? [],
    isProductSelected,
    isLoading,
    toggleProduct,
    resetProducts,
    addProducts,
  }
}

// export const useSelectProduct = () => {
//   const { toggleProducts, products } = useTrackedPIStore()
//   const { vars } = useMIVars()

//   const isProductSelected = useCallback(
//     (p_c_id) => products.findIndex((p) => p.id === p_c_id) > -1,
//     [products]
//   )
//   const { mutate: enqueueClaimsImages } = trpc.product_insights.enqueueClaimsImages.useMutation()

//   const toggleProduct = useCallback(
//     (p_c_id) => {
//       const isProductSelected = products.findIndex((p) => p.id === p_c_id) > -1
//       if (!isProductSelected) {
//         if (products.length > 0)
//           notifySuccess('Success!', 'The product has been added to the comparison list.', 2000)
//         enqueueClaimsImages({ p_c_ids: [p_c_id], geo: vars.geo })
//       }

//       toggleProducts([{ id: p_c_id }])
//     },
//     [enqueueClaimsImages, products, toggleProducts, vars.geo]
//   )

//   return { isProductSelected, toggleProduct }
// }
