import * as React from 'react'

function Instagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 5.0383C7.53633 5.0383 5.08069 7.45161 5.08069 10.4577C5.08069 13.4637 7.494 15.877 10.5 15.877C13.5061 15.877 15.9194 13.4214 15.9194 10.4577C15.9194 7.49394 13.4638 5.0383 10.5 5.0383ZM10.5 13.9294C8.5948 13.9294 7.02827 12.3629 7.02827 10.4577C7.02827 8.55241 8.5948 6.98588 10.5 6.98588C12.4053 6.98588 13.9718 8.55241 13.9718 10.4577C13.9718 12.3629 12.4053 13.9294 10.5 13.9294Z"
        fill="#155EEF"
      />
      <path
        d="M16.131 6.13911C16.8091 6.13911 17.3588 5.5894 17.3588 4.91129C17.3588 4.23318 16.8091 3.68347 16.131 3.68347C15.4529 3.68347 14.9032 4.23318 14.9032 4.91129C14.9032 5.5894 15.4529 6.13911 16.131 6.13911Z"
        fill="#155EEF"
      />
      <path
        d="M19.3065 1.73589C18.2056 0.592742 16.6391 0 14.8609 0H6.13911C2.45565 0 0 2.45564 0 6.13911V14.8185C0 16.6391 0.592742 18.2056 1.77823 19.3488C2.92137 20.4496 4.44556 21 6.18145 21H14.8185C16.6391 21 18.1633 20.4073 19.2641 19.3488C20.4073 18.248 21 16.6815 21 14.8609V6.13911C21 4.36089 20.4073 2.83669 19.3065 1.73589ZM19.1371 14.8609C19.1371 16.1734 18.6714 17.2319 17.9093 17.9516C17.1472 18.6714 16.0887 19.0524 14.8185 19.0524H6.18145C4.91129 19.0524 3.85282 18.6714 3.09073 17.9516C2.32863 17.1895 1.94758 16.131 1.94758 14.8185V6.13911C1.94758 4.86895 2.32863 3.81048 3.09073 3.04839C3.81048 2.32863 4.91129 1.94758 6.18145 1.94758H14.9032C16.1734 1.94758 17.2319 2.32863 17.994 3.09073C18.7137 3.85282 19.1371 4.91129 19.1371 6.13911V14.8609Z"
        fill="#155EEF"
      />
    </svg>
  )
}

export default React.memo(Instagram)
