import * as React from 'react'

function Copyright(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="12"
      viewBox="0 0 32 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4054_510)">
        <path
          d="M31.2369 9.26884C31.2369 9.87911 31.2369 11.0073 31.2369 11.5857C30.3848 11.5067 25.0987 11.0518 22.7401 10.9305L22.7401 0.65662C25.0941 0.527011 29.5289 0.156659 31.0742 0.0154773C31.0742 0.618274 31.0742 1.77589 31.0742 2.3267C29.1016 2.4302 27.0959 2.53277 25.1005 2.61847V4.80099C26.7252 4.7812 28.3489 4.76103 29.9715 4.7311C29.9715 5.37062 29.9715 6.23925 29.9715 6.85753C28.349 6.8276 26.7252 6.80743 25.1005 6.78769V8.96863C27.1507 9.05664 29.2121 9.16251 31.2369 9.26884ZM21.097 7.78328C21.097 5.38815 18.3635 4.91388 16.4585 4.68676C15.6491 4.59026 13.6038 4.28661 13.6038 3.5812C13.6038 3.01034 14.3508 2.53701 15.9775 2.53701C17.2445 2.53701 18.3217 2.82691 19.3023 3.43856C19.4399 3.52436 19.6193 3.49621 19.7245 3.37266L20.8818 2.0125C19.4556 1.00809 17.7746 0.648204 16.1336 0.648204C13.3075 0.648204 11.1701 1.69074 11.1701 3.62177C11.1701 5.79337 13.6415 6.3096 15.1945 6.54802C16.6451 6.77072 18.661 6.85619 18.661 7.79718C18.661 8.6575 17.0456 9.04957 15.8537 9.04957C14.9665 9.04957 14.083 8.84711 12.7993 8.23607C12.6656 8.17242 12.5057 8.20702 12.4097 8.31992L11.2213 9.71654C12.8714 10.6351 14.7799 10.938 15.908 10.938C18.5308 10.938 21.097 9.97235 21.097 7.78328ZM7.00528 4.8096C5.49624 4.79293 3.98812 4.77583 2.48075 4.75037L2.4807 0.227842C1.65408 0.158082 0.827681 0.0841678 0.00170898 0.0012207C0.00170898 0.439579 0.00176279 11.2329 0.00176279 11.5857C0.827681 11.5027 1.65408 11.4288 2.4807 11.3591L2.48075 6.83661C3.98812 6.81119 5.49625 6.79409 7.00528 6.77743L7.00528 11.0438C7.79197 11.0003 8.57877 10.9618 9.36579 10.9303C9.36579 10.6657 9.36579 0.853047 9.36579 0.656628C8.57877 0.625077 7.79197 0.58657 7.00528 0.543128V4.8096Z"
          fill="#155EEF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4054_510">
          <rect width="31.2391" height="11.5887" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default React.memo(Copyright)
