import * as React from 'react'

function Google(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M11.884 4.876H6.123v2.341H9.46A2.815 2.815 0 018.234 9.07c-.405.267-.87.44-1.348.522-.482.08-.98.091-1.461-.004A3.635 3.635 0 014.06 9.01a3.736 3.736 0 01-1.403-1.843 3.565 3.565 0 01.001-2.336 3.712 3.712 0 012.717-2.41 3.698 3.698 0 011.798.072c.493.147.948.411 1.32.76.377-.367.751-.736 1.127-1.103.197-.198.404-.387.595-.59A5.945 5.945 0 008.246.364 6.311 6.311 0 004.12.33 6.112 6.112 0 00.653 3.306a5.89 5.89 0 00-.001 5.388c.399.775.97 1.464 1.663 2.004a6.141 6.141 0 002.225 1.099c1.02.268 2.106.262 3.133.033a5.57 5.57 0 002.506-1.278 5.432 5.432 0 001.55-2.49c.305-1.031.347-2.131.155-3.186z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default React.memo(Google)
